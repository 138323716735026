import {EntityAction} from 'app/blocks/model/action.model';

export class GetConfig {
    static readonly type = '[Company] Get Config';
    constructor() {}
}

export class TrackAsyncRequest {
    static readonly type = '[Async Requests] Track in-progress async requests';
    constructor() {}
}

export class SetGemniniCommands {
    static readonly type = '[SetGemniniCommands] Set Gemini Commands';
    constructor(public entityActions: EntityAction[]) {}
}
