import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {AccountService} from 'app/core/auth/account.service';
import {Store} from '@ngxs/store';
import {GetConfig} from 'app/blocks/store/actions/common-actions';
import {Customer} from 'app/blocks/model/customer.model';
import {IFranchise} from 'app/blocks/model/franchise.model';
import versionJson from '../../../../version.json';
import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';

@Injectable()
export class Principal {
    private userIdentity: any;
    private customer: Customer;
    private authenticated = false;
    private authenticationState = new BehaviorSubject<any>(null);
    private franchise: IFranchise;

    constructor(
        private account: AccountService,
        private store: Store,
        private newrelicAgent?: BrowserAgent
    ) {
        this.authenticationState.subscribe((identity) => {
            if (identity) {
                this.newrelicAgent?.setCustomAttribute('tenant', identity.currentTenant);
                this.newrelicAgent?.setCustomAttribute('appVersion', versionJson.appVersion);
            }
        });
    }

    authenticate(identity): void {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.userIdentity.authorities.includes(authorities[i])) {
                return true;
            }
        }

        return false;
    }

    isAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            (id) => {
                return Promise.resolve(id.authorities.length === 1 && id.authorities.includes(authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            (id) => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    public static userIdentityHasAuthority(userIdentity: any, authority: string): boolean {
        if (!userIdentity || !userIdentity.authorities || !authority) {
            return false;
        }
        return userIdentity.authorities.includes(authority);
    }

    identity(force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account
            .get()
            .toPromise()
            .then((response) => {
                const account = response.body;
                if (account) {
                    this.userIdentity = account;
                    this.authenticated = true;
                    this.customer = account['customer'];
                    this.franchise = account['franchise'];
                    this.store.dispatch(new GetConfig());
                } else {
                    this.userIdentity = null;
                    this.authenticated = false;
                }
                this.authenticationState.next(this.userIdentity);
                return this.userIdentity;
            })
            .catch((err) => {
                this.userIdentity = null;
                this.authenticated = false;
                this.authenticationState.next(this.userIdentity);
                return null;
            });
    }

    isAuthenticated(): boolean {
        console.log('[Principal] isAuthenticated: ' + this.authenticated);
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getCustomer(): Customer {
        return this.customer;
    }

    isUserCustomer(): boolean {
        return this.customer !== null;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    getImageUrl(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }

    getFranchise(): Customer {
        return this.customer;
    }

    isFranchiseUser(): boolean {
        return this.franchise !== null;
    }

    getAccountUser(): any {
        if (!this.authenticated) {
            throw new Error('User is not authenticated. Please Log In again');
        }
        if (this.userIdentity == null) {
            throw new Error('User is not logged in. Please Log In again');
        }
        return this.userIdentity;
    }
}
